// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("/opt/build/repo/src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-form-site-js": () => import("/opt/build/repo/src/pages/form_site.js" /* webpackChunkName: "component---src-pages-form-site-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kurse-js": () => import("/opt/build/repo/src/pages/kurse.js" /* webpackChunkName: "component---src-pages-kurse-js" */),
  "component---src-pages-werke-js": () => import("/opt/build/repo/src/pages/werke.js" /* webpackChunkName: "component---src-pages-werke-js" */)
}

